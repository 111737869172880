export class Message {
  uid: string;
  subject: string;
  text: string;
  date: string;

  constructor(uid: string, subject: string, text: string, date: string) {
    this.uid = uid;    
    this.subject = subject;
    this.text = text;
    this.date = date;
  }
}
