import { Component, OnInit } from '@angular/core';

import { Profile } from '../../../models/profile.model';
import { ProfilesService } from '../../../services/profiles.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  profile: Profile;

  constructor(private profilesService: ProfilesService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.profile = new Profile('', '', '', '', '', '', '', '', '');
    this.profilesService.getSimgleProfile('1El5Z6jA2rNJ9knQty2jyLJNFd22').then((profile: Profile) => {
      this.profile = profile;
    });
  }

}
