import { Injectable } from '@angular/core';
import { Profile } from '../models/profile.model';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {
  profiles: Profile[] = [];
  profilesSubject = new Subject<Profile[]>();

  constructor() {}

  emitProfiles() {
    this.profilesSubject.next(this.profiles);
  }

  // saveProfiles() {
  //   firebase
  //     .database()
  //     .ref('profiles/' + '1El5Z6jA2rNJ9knQty2jyLJNFd22')
  //     .set(this.profiles);
  // }

  getProfiles() {
    firebase
      .database()
      .ref('/profiles')
      .on('value', data => {
        this.profiles = data.val() ? data.val() : [];
        this.emitProfiles();
      });
  }

  getSimgleProfile(uid: string) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref('/profiles/' + uid)
        .once('value')
        .then(
          data => {
            resolve(data.val());
          },
          error => {
            reject(error);
          }
        );
    });
  }

  // createNewProfile(newProfile: Profile) {
  //   console.log(newProfile);
    
  //   this.profiles.push(newProfile);
  //   this.saveProfiles();
  //   this.emitProfiles();
  // }

  // removeProfile(profile: Profile) {
  //   const profileIndexToRemove = this.profiles.findIndex(profileEl => {
  //     if (profileEl === profile) {
  //       return true;
  //     }
  //   });
  //   this.profiles.splice(profileIndexToRemove, 1);
  //   this.saveProfiles();
  //   this.emitProfiles();
  // }

  // uploadFile(file: File) {
  //   return new Promise((resolve, reject) => {
  //     const almostUniqueFileName = Date.now().toString();
  //     const upload = firebase
  //       .storage()
  //       .ref()
  //       .child('images/' + almostUniqueFileName + file.name)
  //       .put(file);
  //     upload.on(
  //       firebase.storage.TaskEvent.STATE_CHANGED,
  //       () => {
  //         console.log('Chargment...');
  //       },
  //       error => {
  //         console.log('Erreur de chargment : ' + error);
  //         reject();
  //       },
  //       () => {
  //         resolve(upload.snapshot.ref.getDownloadURL());
  //       }
  //     );
  //   });
  // }

  getProfileActif() {
    var user = firebase.auth().currentUser;

    if (user) {
      return user.uid;
    } else {
      // No user is signed in.
    }
  }
}
