export class Menu {
  id: number;
  text: string;
  link: string;

  constructor(id: number, text: string, link: string) {
    this.id = id;
    this.text = text;
    this.link = link;
  }
}
