import { Component, OnInit } from '@angular/core';
import { Project } from '../../../../models/project.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProjectsService } from '../../../../services/projects.service';

@Component({
  selector: 'app-exp-pro-graslin',
  templateUrl: './exp-pro-graslin.component.html',
  styleUrls: ['./exp-pro-graslin.component.scss']
})
export class ExpProGraslinComponent implements OnInit {
  project: Project;

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private router: Router
  ) {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.project = new Project('', '', '', '', '');
    this.projectsService.getSimgleProject(+4).then((project: Project) => {
      this.project = project;
    });
  }
}
