import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';
import 'firebase/app';
import 'firebase/auth';
import { Subject } from 'rxjs';
import { Log } from '../models/log.model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  log: Log;
  logsSubject = new Subject<Log>();
  
  constructor(private router: Router) { }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(
      (resolve, reject) => {
        firebase.auth().onAuthStateChanged(
          (user) => {
            if(user) {
              this.sendLog();
              resolve(true);
            } else {
              this.router.navigate(['connection']);
              resolve(false);
            }
          }
        )
      }
    )
  }


  uid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return  v.toString(32);
    });
  }

  emitLog() {
    this.logsSubject.next(this.log);
  }

  saveLog() {
    firebase
      .database()
      .ref("logs/" + this.uid())
      .set(this.log);
  }

  sendLog() {
    this.log = new Log(firebase.auth().currentUser.uid, new Date().toString());
    console.log("ddsdqqdsqdsq");
    
    this.saveLog();
    this.emitLog();
  }
}
