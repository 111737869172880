import { Component, Input } from '@angular/core';
import { Profile } from '../../../models/profile.model';

@Component({
  selector: 'app-profile-block',
  templateUrl: './profile-block.component.html',
  styleUrls: ['./profile-block.component.scss']
})
export class ProfileBlockComponent {
  @Input() profile: Profile;
  constructor() {}
}
