import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Profile } from '../../../models/profile.model';
import { ProfilesService } from '../../../services/profiles.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profile: Profile;

  constructor(private profilesService: ProfilesService) {}

  ngOnInit() {
    this.profile = new Profile('', '', '', '', '', '', '', '', '');
    const uid = this.profilesService.getProfileActif();
    this.profilesService.getSimgleProfile(uid).then((profile: Profile) => {
      this.profile = profile;
    });
  }
}
