export class Profile {
    uid: string;
    name: string;
    email: string;
    info: string;
    web: string;
    link: string;
    dateCreation: string;

    imgProfile: string;
    imgAltProfile: string;

    imgBackground: string;
    imgAltBackground: string;
  
    constructor(
        uid: string,
        name: string,
        email: string,
        info: string,
        web: string,
        link: string,
        dateCreation: string,
        imgAltProfile: string,
        imgAltBackground: string,
    ) {
      this.uid = uid;
      this.name = name;
      this.email = email;
      this.info = info;
      this.web = web;
      this.link = link;
      this.dateCreation = dateCreation;
      this.imgAltProfile = imgAltProfile;
      this.imgAltBackground = imgAltBackground;
    }
  }
  