import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MsgService } from '../../../services/msg.service';
import { Router } from '@angular/router';
import { Message } from 'src/app/models/message.model';
import { ProfilesService } from '../../../services/profiles.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  sendInForm: FormGroup;
  errorMessage: string;
  messageIsSend: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private msgService: MsgService,
    private router: Router,
    private profilesService: ProfilesService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.sendInForm = this.formBuilder.group({
      subject: ['', [Validators.required, Validators.minLength(4)]],
      text: [
        '',
        [Validators.required, Validators.minLength(10)]
      ]
    });
  }

  OnSubmit() {
    const subject = this.sendInForm.get('subject').value;
    const text = this.sendInForm.get('text').value;
    const date = new Date().toString();
    const uid = this.profilesService.getProfileActif();
    const newMessage = new Message(uid, subject, text, date);
    this.msgService.sendMsg(newMessage);
    this.messageIsSend = true;
    document.getElementById('msg-s').style.display = 'block';
    // this.router.navigate(['/home']);
  }
}
