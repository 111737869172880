import { Component, OnInit } from '@angular/core';
import { Project } from '../../../../models/project.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ProjectsService } from '../../../../services/projects.service';
import { Profile } from '../../../../models/profile.model';
import { ProfilesService } from '../../../../services/profiles.service';

@Component({
  selector: 'app-atomic-design',
  templateUrl: './atomic-design.component.html',
  styleUrls: ['./atomic-design.component.scss']
})
export class AtomicDesignComponent implements OnInit {
  project: Project;
  profile: Profile;

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private router: Router,
    private profilesService: ProfilesService
  ) {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngOnInit() {
    this.project = new Project('', '', '', '', '');
    this.projectsService.getSimgleProject(+1).then((project: Project) => {
      this.project = project;
    });
    this.profile = new Profile(
      '',
      'Nom Prénom',
      'email@email.com',
      'Information',
      'www.site.com',
      '',
      '',
      '',
      ''
    );
    this.profile.imgProfile =
      // tslint:disable-next-line:max-line-length
      'https://firebasestorage.googleapis.com/v0/b/aventu-re.appspot.com/o/images%2Fdefault_img_profile.png?alt=media&token=a19ad1f0-6e92-4bb6-8386-ad8ebba41686';
    this.profile.imgBackground =
      // tslint:disable-next-line:max-line-length
      'https://firebasestorage.googleapis.com/v0/b/aventu-re.appspot.com/o/images%2Fplaceholder.png?alt=media&token=ff191b19-8e73-4cf2-8a04-f8dc23fee591';
  }
}
