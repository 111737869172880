import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProjectsService } from '../../../services/projects.service';
import { Router } from '@angular/router';
import { Project } from '../../../models/project.model';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {
  projects: Project[];
  projectsSubscription: Subscription;

  constructor(
    private projectsService: ProjectsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectsSubscription = this.projectsService.projectsSubject.subscribe(
      (projects: Project[]) => {
        this.projects = projects;
      }
    );
    this.projectsService.getProjects();
    this.projectsService.emitProjects();
  }

  onViewProject(id: number) {
    this.router.navigate(['/projects', 'view', id]);
  }

  ngOnDestroy() {
    this.projectsSubscription.unsubscribe();
  }

  is2019(project) {
    if (project.date.slice(-4) == "2019") {
      return true;
    }
  }

  is2018(project) {
    if (project.date.slice(-4) == "2018") {
      return true;
    }
  }

}
