import { Injectable } from '@angular/core';
import { Project } from '../models/project.model';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  projects: Project[] = [];
  projectsSubject = new Subject<Project[]>();

  constructor() {}

  emitProjects() {
    this.projectsSubject.next(this.projects);
  }

  // saveProjects() {
  //   firebase
  //     .database()
  //     .ref('/projects')
  //     .set(this.projects);
  // }

  getProjects() {
    firebase
      .database()
      .ref('/projects')
      .on('value', data => {
        this.projects = data.val() ? data.val() : [];
        this.emitProjects();
      });
  }

  getSimgleProject(id: number) {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref('/projects/' + id)
        .once('value')
        .then(
          data => {
            resolve(data.val());
          },
          error => {
            reject(error);
          }
        );
    });
  }

  // createNewProject(newProject: Project) {
  //   this.projects.push(newProject);
  //   this.saveProjects();
  //   this.emitProjects();
  // }

  // removeProject(project: Project) {
  //   const projectIndexToRemove = this.projects.findIndex(projectEl => {
  //     if (projectEl === project) {
  //       return true;
  //     }
  //   });
  //   this.projects.splice(projectIndexToRemove, 1);
  //   this.saveProjects();
  //   this.emitProjects();
  // }

  // uploadFile(file: File) {
  //   return new Promise((resolve, reject) => {
  //     const almostUniqueFileName = Date.now().toString();
  //     const upload = firebase
  //       .storage()
  //       .ref()
  //       .child('images/' + almostUniqueFileName + file.name)
  //       .put(file);
  //     upload.on(
  //       firebase.storage.TaskEvent.STATE_CHANGED,
  //       () => {
  //         console.log('Chargment...');
  //       },
  //       (error) => {
  //         console.log('Erreur de chargment : ' + error);
  //         reject();
  //       },
  //       () => {
  //         resolve(upload.snapshot.ref.getDownloadURL());
  //       }
  //     );
  //   });
  // }
}
