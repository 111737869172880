// export class Project {
//   photo: string;
//   synopsis: string;
//   constructor(public title: string, public author: string) {}
// }

export class Project {
  // id: number;
  title: string;
  author: string;
  date: string;
  description: string;
  imgAlt: string;
  img: string;

  constructor(
    // id: number,
    title: string,
    author: string,
    date: string,
    description: string,
    imgAlt: string,
  ) {
    // this.id = id;
    this.title = title;
    this.author = author;
    this.date = date;
    this.description = description;
    this.imgAlt = imgAlt;
  }
}
