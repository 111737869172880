import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// *** Module Route ***
import { AppRoutingModule } from './app-routing.module';

// *** Services ***
import { AuthService } from './services/auth.service';
import { ProfilesService } from './services/profiles.service';
import { ProjectsService } from './services/projects.service';
import { AuthGuardService } from './services/auth-guard.service';

// *** Components ***
import { AppComponent } from './app.component';
// Atoms
import { MenuComponent } from './components/atoms/menu/menu.component';
// Molecules
import { ProfileBlockComponent } from './components/molecules/profile-block/profile-block.component';
import { ProjectBlockComponent } from './components/molecules/project-block/project-block.component';
// Organisms
import { AuthComponent } from './components/organisms/auth/auth.component';
import { HeaderComponent } from './components/organisms/header/header.component';
import { NavbarComponent } from './components/organisms/navbar/navbar.component';
// Pages
import { ConnectionComponent } from './components/pages/connection/connection.component';

import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { ErrorComponent } from './components/pages/error/error.component';

import { ProjectListComponent } from './components/pages/project-list/project-list.component';
import { SingleProjectComponent } from './components/pages/project-list/single-project/single-project.component';
import { InternshipStudiofloaxComponent } from './components/pages/project-list/internship-studiofloax/internship-studiofloax.component';
import { AtomicDesignComponent } from './components/pages/project-list/atomic-design/atomic-design.component';
import { AngularSixComponent } from './components/pages/project-list/angular-six/angular-six.component';
import { WebsiteDesignComponent } from './components/pages/project-list/website-design/website-design.component';
import { ExpProGraslinComponent } from './components/pages/project-list/exp-pro-graslin/exp-pro-graslin.component';
import { MakeOperaComponent } from './components/pages/project-list/make-opera/make-opera.component';

import { CvComponent } from './components/pages/cv/cv.component';
import { ContactComponent } from './components/pages/contact/contact.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngersNantesOperaComponent } from './components/pages/project-list/angers-nantes-opera/angers-nantes-opera.component';
import { HistoryGraslinComponent } from './components/pages/project-list/history-graslin/history-graslin.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { CultureComponent } from './components/pages/project-list/culture/culture.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    AuthComponent,
    ConnectionComponent,
    ProjectBlockComponent,
    MenuComponent,
    NavbarComponent,
    HomeComponent,
    AboutComponent,
    ProfileComponent,
    ErrorComponent,
    ProfileBlockComponent,
    ProjectListComponent,
    SingleProjectComponent,
    InternshipStudiofloaxComponent,
    AtomicDesignComponent,
    AngularSixComponent,
    WebsiteDesignComponent,
    ExpProGraslinComponent,
    MakeOperaComponent,
    CvComponent,
    ContactComponent,
    AngersNantesOperaComponent,
    HistoryGraslinComponent,
    BlogComponent,
    CultureComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule
  ],
  providers: [
    AuthService,
    ProfilesService,
    ProjectsService,
    AuthGuardService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
