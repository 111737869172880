import { Injectable } from '@angular/core';
import { Message } from '../models/message.model';
import { Subject } from 'rxjs';
import * as firebase from 'firebase';
import 'firebase/app';
import 'firebase/database';
import 'firebase/storage';

@Injectable({
  providedIn: 'root'
})
export class MsgService {
  message: Message;
  messagesSubject = new Subject<Message>();

  constructor() {}

  uid() {
    return 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, c => {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return  v.toString(32);
    });
  }

  emitMessage() {
    this.messagesSubject.next(this.message);
  }

  saveMessage() {
    firebase
      .database()
      .ref("messages/" + this.uid())
      .set(this.message);
  }

  sendMsg(newMsg: Message) {
    this.message = newMsg;
    this.saveMessage();
    this.emitMessage();
  }
}
