import { Component, HostListener } from '@angular/core';
import { Menu } from '../../../models/menu.model';
import { Router } from '@angular/router';

import { AuthService } from '../../../services/auth.service';
import { ProfilesService } from '../../../services/profiles.service';
import { Profile } from 'src/app/models/profile.model';
import { faBars, faMinus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  profile: Profile;
  open: boolean = false;
  windowX: boolean = false;
  faBars = faBars;
  faMinus = faMinus;

  constructor(
    private router: Router,
    private authService: AuthService,
    private profilesService: ProfilesService
  ) {
    this.profile = new Profile('', '', '', '', '', '', '', '', '');
    const uid = this.profilesService.getProfileActif();

    this.profilesService.getSimgleProfile(uid).then((profile: Profile) => {
      this.profile = profile;
    });

    router.events.subscribe(() => {
      document.getElementById('menu').style.display = 'none';
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.router.url != '/connection') {
      if (event.target.innerWidth > 1033) {
        this.open = false;
        document.getElementById('menu').style.display = 'none';
        document.getElementById('navbar').style.top = '0';
      } else {
        document.getElementById('navbar').style.top = '0';
      }
    }
  }

  menu() {
    if (this.router.url != '/connection') {
      this.open = !this.open;
      if (this.open) {
        document.getElementById('menu').style.display = 'flex';
      } else {
        document.getElementById('menu').style.display = 'none';
      }
    }
  }

  menus: Menu[] = [
    new Menu(1, 'Accueil', '/home'),
    new Menu(2, 'Projets', '/projects'),
    new Menu(3, 'Blog', '/blog'),
    new Menu(4, 'A propos', '/about'),
    new Menu(5, 'Contact', '/contact')
    // new Menu(3, 'Nouveau projet', '/projects/new'),
    // new Menu(3, 'Nouveau profil', '/profile/new'),
  ];

  onSignOut() {
    this.authService.signOutUser();
  }
}
