import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ConnectionComponent } from './components/pages/connection/connection.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ProfileComponent } from './components/pages/profile/profile.component';

import { ErrorComponent } from './components/pages/error/error.component';

import { AuthGuardService } from './services/auth-guard.service';

import { ProjectListComponent } from './components/pages/project-list/project-list.component';
import { SingleProjectComponent } from './components/pages/project-list/single-project/single-project.component';
import { NavbarComponent } from './components/organisms/navbar/navbar.component';
import { InternshipStudiofloaxComponent } from './components/pages/project-list/internship-studiofloax/internship-studiofloax.component';
import { AtomicDesignComponent } from './components/pages/project-list/atomic-design/atomic-design.component';
import { AngularSixComponent } from './components/pages/project-list/angular-six/angular-six.component';
import { WebsiteDesignComponent } from './components/pages/project-list/website-design/website-design.component';
import { ExpProGraslinComponent } from './components/pages/project-list/exp-pro-graslin/exp-pro-graslin.component';
import { MakeOperaComponent } from './components/pages/project-list/make-opera/make-opera.component';
import { HistoryGraslinComponent } from './components/pages/project-list/history-graslin/history-graslin.component';
import { CultureComponent } from './components/pages/project-list/culture/culture.component';
import { AngersNantesOperaComponent } from './components/pages/project-list/angers-nantes-opera/angers-nantes-opera.component';

import { BlogComponent } from './components/pages/blog/blog.component';

import { CvComponent } from './components/pages/cv/cv.component';
import { ContactComponent } from './components/pages/contact/contact.component';


const appRoutes: Routes = [
  {
    path: 'connection',
    component: ConnectionComponent
  },
  {
    path: 'projects',
    canActivate: [AuthGuardService],
    component: ProjectListComponent
  },
  {
    path: 'projects/view/0',
    canActivate: [AuthGuardService],
    component: InternshipStudiofloaxComponent
  },
  {
    path: 'projects/view/1',
    canActivate: [AuthGuardService],
    component: AtomicDesignComponent
  },
  {
    path: 'projects/view/2',
    canActivate: [AuthGuardService],
    component: AngularSixComponent
  },
  {
    path: 'projects/view/3',
    canActivate: [AuthGuardService],
    component: WebsiteDesignComponent
  },
  {
    path: 'projects/view/4',
    canActivate: [AuthGuardService],
    component: ExpProGraslinComponent
  },
  {
    path: 'projects/view/5',
    canActivate: [AuthGuardService],
    component: MakeOperaComponent
  },
  {
    path: 'projects/view/6',
    canActivate: [AuthGuardService],
    component: HistoryGraslinComponent
  },
  {
    path: 'projects/view/7',
    canActivate: [AuthGuardService],
    component: CultureComponent
  },
  {
    path: 'projects/view/8',
    canActivate: [AuthGuardService],
    component: AngersNantesOperaComponent
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    canActivate: [AuthGuardService],
    component: HomeComponent
  },
  {
    path: 'about',
    canActivate: [AuthGuardService],
    component: AboutComponent
  },
  {
    path: 'cv',
    canActivate: [AuthGuardService],
    component: CvComponent
  },
  {
    path: 'blog',
    canActivate: [AuthGuardService],
    component: BlogComponent
  },
  {
    path: 'contact',
    canActivate: [AuthGuardService],
    component: ContactComponent
  },
  {
    path: 'profile',
    canActivate: [AuthGuardService],
    component: ProfileComponent
  },
  {
    path: 'error',
    canActivate: [AuthGuardService],
    component: ErrorComponent
  },
  {
    path: '**',
    redirectTo: 'error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
