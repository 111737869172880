import { Component } from '@angular/core';
import * as firebase from 'firebase';
import 'firebase/app';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor() {
    const config = {
      apiKey: 'AIzaSyBE_3dHKoWcyAFf1Lw4Idyqilh0KW4amz8',
      authDomain: 'aventu-re.firebaseapp.com',
      databaseURL: 'https://aventu-re.firebaseio.com',
      projectId: 'aventu-re',
      storageBucket: 'aventu-re.appspot.com',
      messagingSenderId: '769676417665'
    };
    firebase.initializeApp(config);
  }
}
