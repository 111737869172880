import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  constructor() { }

  scroll() {
    window.scroll({
      top: 2500,
      left: 0,
      behavior: 'smooth'
    });
  }
}
